import Main from '@/views/layout/index.vue'
import Mobile from '@/views/layoutMobile/index.vue'
const routes = [
  {
    path: '/',
    name: 'main',
    redirect: '/login',
    component: Main,
    children: [
      {
        path: '/login',  // 登陆
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/user/login/index.vue')
      }, {
        path: '/register',  // 注册
        name: 'register',
        component: () => import(/* webpackChunkName: "register" */ '../views/user/register/index.vue')
      }, {
        path: '/mobileverify',  // 验证手机号
        name: 'mobileverify',
        component: () => import(/* webpackChunkName: "mobileverify" */ '../views/user/mobileverify/index.vue')
      }, {
        path: '/forget',  // 忘记密码
        name: 'forget',
        component: () => import(/* webpackChunkName: "forget" */ '../views/user/forget/index.vue')
      }, {
        path: '/resetpass',  // 重置密码
        name: 'resetpass',
        component: () => import(/* webpackChunkName: "resetpass" */ '../views/user/resetpass/index.vue')
      }, {
        path: '/modifypass',  // 修改密码
        name: 'modifypass',
        component: () => import(/* webpackChunkName: "resetpass" */ '../views/user/modifypass/index.vue')
      },
    ]
  },
  {
    path: '/',
    name: 'mainMobile',
    component: Mobile,
    children: [
      {
        path: '/loginMobile',  // 登陆
        name: 'loginMobile',
        component: () => import(/* webpackChunkName: "loginMobile" */ '../views/userMobile/login/index.vue')
      },
      {
        path: '/forgetMobile',  // 忘记密码
        name: 'forgetMobile',
        component: () => import(/* webpackChunkName: "forgetMobile" */ '../views/userMobile/forget/index.vue')
      },
      {
        path: '/registerMobile',  // 注册
        name: 'registerMobile',
        component: () => import(/* webpackChunkName: "registerMobile" */ '../views/userMobile/register/index.vue')
      },
      {
        path: '/mobileverifyMobile',  // 验证手机号
        name: 'mobileverifyMobile',
        component: () => import(/* webpackChunkName: "mobileverifyMobile" */ '../views/userMobile/mobileverify/index.vue')
      },
      {
        path: '/resetpassMobile',  // 重置密码
        name: 'resetpassMobile',
        component: () => import(/* webpackChunkName: "resetpass" */ '../views/userMobile/resetpass/index.vue')
      },
    ]
  },
  {
    path: '/500',
    name: '500',
    component: () => import(/* webpackChunkName: "500" */'../views/error/500.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */'../views/error/404.vue')
  },
  {
    path: '/dataPro',  // 修改密码
    name: 'dataPro',
    component: () => import(/* webpackChunkName: "resetpass" */ '../views/DataPro/index.vue')
  },
  // {
  //   path: '/demo',
  //   name: 'Demo',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/demo')
  // }
];

export default routes
