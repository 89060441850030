let domain = location.host.indexOf('cn')>-1 ? 'cic-tp.cn' : 'cic-tp.com';
let apiUrl = location.host.indexOf('cn')>-1 ? '//api.cic-tp.cn' : '//api.cic-tp.com';
let cicUrl = location.host.indexOf('cn')>-1 ? '//www.cic-tp.cn' : '//www.cic-tp.com';
let tradeUrl = location.host.indexOf('cn')>-1 ? '//trade.cic-tp.cn' : '//trade.cic-tp.com';
export default {
  apiUrl:apiUrl,
  domain:domain,
  cicUrl:cicUrl,
  tradeUrl:tradeUrl
}
