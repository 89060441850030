import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routers'
import i18n from '../i18n'
import axios from 'axios'
import utils from '../util/utils'
Vue.use(VueRouter);

const router = new VueRouter({
  routes
});

import langEn from 'element-ui/lib/locale/lang/en'
import langZH from 'element-ui/lib/locale/lang/zh-CN'
import locale from 'element-ui/lib/locale'

// 全局守卫 根据当前语言获取翻译字典
router.beforeEach((from, to, next) => {
  let clubInviteKey = utils.getUrlParam(from.query.redirect,'clubInviteKey') || from.query.clubInviteKey;
  if(clubInviteKey){
    window.sessionStorage.setItem('clubInviteKey',clubInviteKey);
  }
  //是否存在 俱乐部邀请id

  if (from.query['language']){
    window.localStorage.setItem('languageCode',from.query['language']) //如果服务端传了语言过来，就取
  }
  let languageCode = window.localStorage.getItem("languageCode") || 'en';
  if(languageCode == 'zh_cn'){
    // 设置语言
    locale.use(langZH);
  }else{
    locale.use(langEn);
  }
  axios.get("/static/language/" + languageCode + ".json").then(res => {
    i18n.locale = languageCode;
    i18n.mergeLocaleMessage(languageCode, res);
    next();
  });
});



export default router
