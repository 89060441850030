export default {

  /**
   * 将js对象转换成url参数
   * @param obj
   * @returns {string}
   */
  urlencode(obj) {
    var _result = [];
    for (var key in obj) {
      var value = obj[key];
      if (Array.isArray(value)) {
        value.forEach(function (_value) {
          _result.push(key + "=" + encodeURIComponent(_value));
        });
      } else {
        _result.push(key + '=' + encodeURIComponent(value));
      }
    }
    return _result.join('&');
  },

  isObjFn(o) {
    if(typeof o === 'string' && o.length) {
      return true;
    }

    return Object.prototype.toString.call(o) === "[object Object]";
  },

  /**
   * 是否是有效的JSON
   * @param obj
   * @returns {boolean}
   */
  isValidJSON(obj) {
    try {
      JSON.parse(obj);
      return true;
    } catch (e) {
      return false;
    }
  },

  /**
   * 验证数字，且最多只能有两位小数
   * @param val
   * @returns {boolean}
   */
  isMoney(val) {
    // let reg = /^\d+(\.\d{1,2})?$/;
    let reg = /^(\d{0,12})(\.(\d{0,4}))?$/g;
    return reg.test(val);
  },
  isInterRest(val){
    let reg = /^(\d{0,6})(\.(\d{0,2}))?$/g;
    return reg.test(val);
  },
  //数字补位 且最多x位
  convertNum(value,sliceNum) {
    let parseFloatVal=parseFloat(value);
    if(isNaN(parseFloatVal)){
      return
    }else{
      if (parseFloatVal >= 0) {
        let valueNew = parseFloatVal.toString();
        let intPart;
        if (Number(valueNew) >= 0) {
          intPart = Math.floor(Number(valueNew)); //获取整数部分
        } else if (Number(valueNew) < 0) {
          intPart = Math.ceil(Number(valueNew)); //获取整数部分
        }
        let intPartFormat = intPart.toString();
        let floatPart = ".00"; //预定义小数部分
        //数组分割出小数部分和整数部分
        let value2Array = valueNew.split(".");
        //如果有小数位
        if (value2Array[1] && value2Array[1].length > 0) {
          floatPart = value2Array[1]; //拿到小数部分  此处是字符串
          if (floatPart.length === 1) {
            return intPartFormat + "." + floatPart + '0';
          } else {
            return intPartFormat + "." + floatPart.slice(0, sliceNum);
          }

        } else {//如果没有小数位则补0
          return intPartFormat + floatPart;
        }
      }
    }
  },

  /**
   * 验证数字输入
   * @param value 整数部分
   * @param num   保留几位整数    floatNum  保留几位小数
   * @returns {value}
   */
  dealInputVal(value,num,floatNum) {
    value = value.replace(/^0*(0\.|[1-9])/, "$1");
    value = value.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
    value = value.replace(/^\./g, ""); //验证第一个字符是数字而不是字符
    value = value.replace(/\.{1,}/g, "."); //只保留第一个.清除多余的
    value = value
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
    // value = value.replace(/^(\-)*(\d*)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
    if (!floatNum){
      floatNum = 2  //兼容老数据，没有传小数位数，默认保留2位小数
    }
    value =
        value.indexOf(".") > 0
            ? value.split(".")[0].substring(0, num) + "." + value.split(".")[1].substring(0, floatNum)
            : value.substring(0, num);
    return  value;
  },

  dealInputPassswordVal(value,num){
    value = value.replace(/[^\d]/g, '')
    return value.substring(0,num)
  },
  /* -----------------------------localStorage------------------------------------ */

  /*
   * set localStorage
   */
  setItem(name, content) {
    if (!name) return;
    if (typeof content !== 'string') {
      content = JSON.stringify(content)
    }
    window.localStorage.setItem(name, content)
  },

  /**
   * get localStorage
   */
  getItem(name) {
    if (!name) return
    let content = window.localStorage.getItem(name)
    //return JSON.parse(content)
    return content;

  },

  /**
   * delete localStorage
   */
  removeItem(name) {
    if (!name) return
    window.localStorage.removeItem(name)
  },

  isPC(){
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone",
      "SymbianOS", "Windows Phone",
      "iPad", "iPod"];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  },
  // 获取url参数
  getUrlParam(url,name){
    if(!url){
      return false
    }
    let reg= new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
    let result;
    try {
      result = url.split('?')[1].match(reg);
    } catch (err) {
      result = '';
    }
    // console.log(result);
    return result ? decodeURIComponent(result[2]) : null;
  },


}
