<template>
  <el-container class="layout-box">
    <el-header class="header">
      <div class="box">
        <div class="logo"><img width="100px" src="@/assets/images/logo.png">
          <div class="logo1"><img width="200px" src="@/assets/images/logo1.png"> </div>
        </div>

        <div class="h-r">
          <!-- v-if="$route.name =='resetpass'" -->
          <div class="h-user1" @click="goRegist"  v-if="$route.name !='modifypass'">
            <span slot="reference" class="loginbox"><div class="signicon"></div><span>{{signup}}</span></span>
          </div>
          <div class="h-user" @click="goLogin" v-if="$route.name !='modifypass'">
            <span slot="reference" class="loginbox"><div class="loginicon"></div><span>{{login}}</span></span>
          </div>
          <div class="h-user"  >
            <el-dropdown @command="changeLanguage" width="140">
              <span class="el-dropdown-link">
                <img  v-if="language=='English'" class="countryicon" src="@/assets/images/en.png" />
                <img  v-else class="countryicon" src="@/assets/images/ch.png" />

                {{language}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="en" >English</el-dropdown-item>
                <el-dropdown-item command="zh_cn">中文</el-dropdown-item>

              </el-dropdown-menu>
            </el-dropdown>

          </div>
        </div>
      </div>
    </el-header>
<!--      头部-->

    <el-main class="layout-main">
      <router-view class="page-main"></router-view>
    </el-main>
<!--      主体页面-->

    <el-footer class="footer">{{copyright}}</el-footer>
  </el-container>
</template>

<script>
  import utils from '../../util/utils'
export default {
    inject:['reload'],
  data(){
    return {
      isCollapse: false, // 是否收起左侧菜单 默认展开
      activeMenu:'home', // 当前激活的菜单
      language:'English',
      signup:'Sign up',
      login:'Log in',
      copyright:'Commodities Intelligent Centre © 2020'
    }
  },
  watch: {
    '$route'(newRoute) {
      console.log(newRoute);
    },
  },
  methods:{
    toggleMenu(){
      this.isCollapse = !this.isCollapse;
    },
    goLogin(){
      this.$router.replace({
        path: '/login',
      })
    },
    goRegist(){
      this.$router.replace({
        path: '/register',
      })
    },
    changeLanguage(command) {
      utils.setItem("languageCode",command);
      //this.reload();
      this.$router.go(0);
    }
  },
  mounted() {
    this.activeMenu = this.$route.name;
    var lang = utils.getItem("languageCode");
    if(lang == "zh_cn"){
      this.language ="中文";
    } else {
      this.language ="English";
    }
    this.signup = this.$t('CTLogin.signup');
    this.login = this.$t('CTLogin.login');
    this.copyright = this.$t('CTLogin.copyright');
  }

}
</script>
<style lang="less" scoped>
  @import "./index.less";
</style>
