
export default {
  //每三位数字进行分割
  formatNum(value) {
    let parseFloatVal=parseFloat(value);
    if(isNaN(parseFloatVal)){
      return
    }else {
      if (parseFloatVal || parseFloatVal === 0) {
        let valueNew = parseFloatVal.toString();
        let intPart;
        if (Number(valueNew) >= 0) {
          intPart = Math.floor(Number(valueNew)); //获取整数部分
        } else if (Number(valueNew) < 0) {
          intPart = Math.ceil(Number(valueNew)); //获取整数部分
        }
        intPart = intPart.toString();
        let intPartFormat = intPart.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分逢三一断
        let floatPart = ".00"; //预定义小数部分
        let value2Array = valueNew.split(".");
        //=2表示数据有小数位
        if (value2Array[1] && value2Array[1].length > 0) {
          floatPart = value2Array[1]; //拿到小数部分  此处是字符串
          if (floatPart.length === 1) {
            return intPartFormat + "." + floatPart + '0';
          } else {
            return intPartFormat + "." + floatPart.slice(0, 4);
          }

        } else {
          return intPartFormat + floatPart;
        }
      }
    }
  },

  //格式化数字 超过99 显示99+
  countNum(num) {
    if (num > 99) {
      return "99+"
    } else {
      return num
    }
  },


}
