import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios';
import qs from 'qs'
import utils from './utils'
import config from '../config'
import router from '../router/index'
import { Message } from "element-ui";
import moment from 'moment'
Vue.use(VueAxios, axios);
// axios.defaults.baseURL="//api.cic-tp.com";
axios.defaults.baseURL = config.apiUrl;
axios.defaults.withCredentials = true;

// http 请求 拦截器
axios.interceptors.request.use(
  config => {
    let token = Vue.$cookies.get('token');
    config.headers.token = token;
    config.headers.timestamp = moment().format('MMMM Do YYYY, h:mm:ss a');
    config.headers.language = utils.getItem("language") || 'en';
    config.headers.v = Math.random();

    if (config.method === 'post') {
      // let data = qs.parse(config.data)
      // config.data = qs.stringify({
      //   token:token,
      //   language: utils.getItem("languageCode") || 'en',
      //   clubInviteKey: window.sessionStorage.getItem("clubInviteKey") || '',
      //   v: Math.random(),
      //   timestamp:new Date().getTime(),
      //   ...data
      // })
      let data = config.data;
      if(config.headers['Content-Type'] !== 'application/json'){
        config.data = qs.stringify(qs.parse({
          timestamp : moment().format('MMMM Do YYYY, h:mm:ss a'),
          language: utils.getItem("language") || 'en',
          token:token,
          v: Math.random(),
          ...data
        }));
      }else{
        config.data = {
          timestamp : moment().format('MMMM Do YYYY, h:mm:ss a'),
          language: utils.getItem("language") || 'en',
          token:token,
          v: Math.random(),
          ...data
        }
      }
    } else if (config.method === 'get') {
      config.params = {
        token:token,
        language: utils.getItem("languageCode") || 'en',
        clubInviteKey: utils.getItem("clubInviteKey") || '',
        v: Math.random(),
        timestamp:new Date().getTime(),
        ...config.params
      }
    }
    return config
  },
  err => {
    return Promise.reject(err)
  },
);

// http 响应 拦截器
axios.interceptors.response.use(
  res => {
    if (res.status === 200) {
      if (res.data.status) {
        return Promise.resolve(res.data);
      } else {
        switch (res.data.errorCode) {
          case '401': case '20014':
            router.currentRoute.path !== '/' &&
            router.replace({
              path: '/',
              query: { redirect: router.currentRoute.name },
            });
            return Promise.resolve(res.data);
          case '404':
            router.push("/404");
            break;
          case '500':
            // router.push("/500")
            break;
          default:
            return Promise.resolve(res.data);
        }
      }
    }
  },
  err => {
    Message.error(err.message);
  },
)
