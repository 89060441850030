<template>
  <div id="app">
<!--    <wrap>-->
<!--      <router-view/>-->
<!--    </wrap>-->
    <router-view v-if="IsRouterAlive"/>
  </div>
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

</style>
<script>

// @ is an alias to /src
// import Wrap from '@/components/wrap'
export default {
  // components: {
  //   Wrap
  // },
  data() {
    return {
        IsRouterAlive:true
    };
  },
    provide(){
      return {
          reload:this.reload
      }
    },
    methods:{
      reload(){
          this.IsRouterAlive = false;
          this.$nextTick(function () {
              this.IsRouterAlive = true;
          })
      }
    }
};
</script>
