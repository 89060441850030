import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

/* ------------------------Vue Global Config-------------------------------- */
Vue.config.productionTip = false;

/* ------------------------过滤器------------------------------ */
import Filters from './util/filters'
for (let key in Filters) {
  Vue.filter(key, Filters[key])
}
/* ------------------------公共插件/方法--------------------------------------- */
import config from './config/index'
import './util/axios'
import utils from './util/utils'
import _ from 'lodash';
import moment from 'moment'
Vue.prototype.$config = config;
Vue.prototype._ = _;
Vue.prototype.$utils = utils;
Vue.prototype.$moment = moment;
Vue.prototype.$showLog = false;
// moment.locale('zh-cn');

import VueCookies from 'vue-cookies'
Vue.use(VueCookies);
Vue.$cookies.config('30d','/',config.domain);

/* ------------------------全局组件------------------------------ */
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/style/theme/index.css';
import './assets/style/common.less'
import "./assets/style/page.less";
Vue.use(ElementUI);
Vue.prototype.$message = ElementUI.Message;

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
