<template>
  <div class="layout-mobile">
    <div class="header">
      <img src="@/assets/images/logo.png"><div class="logo"><img src="@/assets/images/logo1.png"> </div>
    </div>
<!--      头部-->

    <router-view class="page-mobile"></router-view>
<!--      主体页面-->

    <div class="footer">
      <div class="logo"><img src="@/assets/images/logo.png"></div>
      <p><i class="iconfont icon-tel"></i>+65-63038899 （WD 9:00-18:00）</p>
      <p><i class="iconfont icon-service"></i>+65-63038896 （7x24h）</p>
      <p><i class="iconfont icon-email"></i>service@cic-tp.com</p>
      <div class="copyRight">
        <p>{{$t('ctLoginMobile.copyright')}}</p>
        <p><img src="@/assets/images/beian.png"> {{$t('ctLoginMobile.copyright_1')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import utils from '../../util/utils'
export default {
    inject:['reload'],
  data(){
    return {
      isCollapse: false, // 是否收起左侧菜单 默认展开
      activeMenu:'home', // 当前激活的菜单
      language:'English',
      signup:'Sign up',
      login:'Log in',
      copyright:'Commodities Intelligent Centre © 2020'
    }
  },
  watch: {
    '$route'(newRoute) {
      console.log(newRoute);
    },
  },
  methods:{
    toggleMenu(){
      this.isCollapse = !this.isCollapse;
    },
    goLogin(){
      this.$router.replace({
        path: '/login',
      })
    },
    goRegist(){
      this.$router.replace({
        path: '/register',
      })
    },
    changeLanguage(command) {
      utils.setItem("languageCode",command);
      //this.reload();
      this.$router.go(0);
    }
  },
  mounted() {
    this.activeMenu = this.$route.name;
    var lang = utils.getItem("languageCode");
    if(lang == "zh_cn"){
      this.language ="中文";
    } else {
      this.language ="English";
    }
    this.signup = this.$t('CTLogin.signup');
    this.login = this.$t('CTLogin.login');
    this.copyright = this.$t('CTLogin.copyright');
  }

}
</script>
<style lang="less" scoped>
  @import "./index.less";
</style>
