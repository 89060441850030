import Vue from 'vue'
import VueI18n from 'vue-i18n'
import utils from '../util/utils'
Vue.use(VueI18n);

let lang = utils.getItem("languageCode") || 'zh_cn';

const messages = {
  zh_cn: {
    "login": {
      "login": "登录",
    },
  },
  en: {
    "login": {
      "login": "Login",
    }
  },
};

export default new VueI18n({
  locale: lang, // set locale
  messages,
  silentTranslationWarn: true
})
