
export default {
  state: {
    token: 'token123',
  },
  mutations: {
    setUserInfo (state, payload) {
      Object.keys(payload).forEach(function (key) {
        state[key] = payload[key];
      });
    },
  },
  getters: {

  },
  actions: {

  }

}
